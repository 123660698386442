<template>
  <div id="home">
    <el-container>
      <el-header>
        <div class="header__container">
          <div class="header__img">
            <el-image
              style="width: auto; height: auto"
              :src="require('@/assets/icon/logo01.png')"
              fit="contain"
            ></el-image>
          </div>
          <div class="header__title__cn">全国互联网安全管理服务平台</div>
          <div class="header__title__en">
            National Internet Security Management Service Platform
          </div>
        </div>
      </el-header>
      <el-main>
        <div class="main__content">
          <div class="main__content__centerwarp">
            <div id="query">
              <div class="query__title">
                <el-image
                  :src="require('@/assets/portalImage/icon5.png')"
                  fit="contain"
                ></el-image>
                公共查询
              </div>
              <el-tabs
                v-model="activeName"
                type="card"
                @tab-click="handleClickTab"
              >
                <el-tab-pane name="web">
                  <span>
                    <el-image
                      style="position: relative; top: 12px"
                      :src="require('@/assets/portalImage/icon33.png')"
                      fit="contain"
                    >
                    </el-image>
                    网站备案查询
                  </span>
                </el-tab-pane>
                <el-tab-pane name="app">
                  <span>
                    <el-image
                      style="position: relative; top: 12px"
                      :src="require('@/assets/portalImage/icon44.png')"
                      fit="contain"
                    >
                    </el-image>
                    APP注册登记查询
                  </span>
                </el-tab-pane>
              </el-tabs>
              <router-view></router-view>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <div class="footer__container">
          <div class="info">
            <el-row>
              <el-col :span="6"
                ><span style="float: left"
                  >邮箱：support@beian.gov.cn</span
                ></el-col
              >
              <el-col :span="6"><span>邮编：100741</span></el-col>
              <el-col :span="6"><span>地址：北京市东长安街14号</span></el-col>
              <el-col :span="6"
                ><span style="float: right"
                  >网站标识码：bm09000026</span
                ></el-col
              >
            </el-row>
          </div>
          <el-divider></el-divider>
          <div class="DownContent">
            <div class="leftPicture">
              <div class="pic1">
                <a @click="handleClickZhaoCuo()">
                  <el-image
                    style="
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      margin-left: -55px;
                      margin-top: -27px;
                    "
                    :src="require('@/assets/web1.png')"
                    fit="contain"
                  ></el-image>
                </a>
              </div>
              <div class="pic2">
                <el-image
                  style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-left: -28px;
                    margin-top: -34px;
                  "
                  :src="require('@/assets/web2.png')"
                  fit="contain"
                ></el-image>
              </div>
            </div>
            <div class="leftFont">
              <p class="white">版权所有：公安部网络安全保卫局</p>
              <p class="white">
                <el-image
                  :src="require('@/assets/web4.png')"
                  fit="contain"
                ></el-image>
                <a
                  class="jgawb"
                  href="https://beian.mps.gov.cn/#/query/webSearch?code=11010102002019"
                  target="_blank"
                >
                  &nbsp; 京公网安备 11010102002019号</a
                >
              </p>
              <a
                class="jicpb"
                style="color: #749ae3 !important"
                href="https://beian.miit.gov.cn/#/Integrated/index"
                target="_blank"
                >京ICP备05070602号&nbsp;&nbsp;&nbsp;</a
              >
            </div>
            <div class="rightPicture">
              <el-image
                style="float: left"
                :src="require('@/assets/web3.png')"
                fit="contain"
              >
              </el-image>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { whoami } from "@/api/user";
import { setStore } from "@/utils/store";

export default {
  name: "App",
  components: {},
  data() {
    return {
      webResult: {},
      appResult: {},
      webState: "search",
      appState: "search",
      activeName: "web",
      activeIndex2: "1",
      formInline: {
        content: "",
      },
    };
  },
  computed: {},
  watch: {},
  created() {
    this.initAESKey();
    const token = this.$store.getters.token;
    // console.log('created this.$store.getters.token=', this.$store.getters.token)
    if (token) {
      whoami()
        .then((res) => {
          const resObject = res.data;
          if (resObject.success && resObject.code === 200) {
            this.$store.dispatch("SetCurrentUser", res.data.data);
          } else {
            this.$store.dispatch("SetCurrentUser", {});
            this.$store.dispatch("SetToken", "");
            location.reload();
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.$store.dispatch("SetCurrentUser", {});
          this.$store.dispatch("SetToken", "");
          location.reload();
        });
    } else {
      //
    }
  },
  methods: {
    handleClickTab(tab) {
      if (tab.name === "app") {
        this.$router.push("/query/appSearch");
      } else if (tab.name === "web") {
        this.$router.push("/query/webSearch");
      }
    },
    handleChangeState() {},
    handleClickRegister() {
      this.$router.push("/portal/userPage/register");
    },
    handleClickLogOut() {
      // 登出操作
      this.$store.dispatch("SetToken", "").then(() => {
        this.$store.dispatch("SetCurrentUser", null).then(() => {
          window.location.reload();
        });
      });
    },
    handleClickMyWorkBench() {
      var url = window.location.href; // 拿到当前页面url,vue编译完成的页面会有'#/'
      var dz_url = url.split("#")[0];
      window.location.href = dz_url + "bcyr/o/" + this.$store.getters.token;
    },
    generateRandomStr(strLength = 16) {
      let code = "";
      const chars = "abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789";
      const charsArr = chars.split("");

      for (let i = 0; i < strLength; i++) {
        const num = Math.floor(Math.random() * charsArr.length);
        code += charsArr[num];
      }
      return code;
    },
    initAESKey() {
      const aeskey = this.generateRandomStr();
      this.aeskey = aeskey;
      setStore({ name: "aesKey", content: aeskey });

      // const zo = RSAENCRY.encryptByPublicKey(aeskey)
      // setStore({ name: 'zo', content: zo })
    },
    handleClickLogin() {
      this.$router.push("/portal/userPage/login");
    },
    handleHorizontalMenuSelect() {},
    handleSelect() {},
    onSubmit() {
      console.log("submit!");
    },
    handleLoginDialogClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
    handleRegisterDialogClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
#query {
  width: 92%;
  margin: 0 auto;
  height: auto;
  padding: 40px 0px;
  .query__title {
    width: 150px;
    margin: 0 auto;
  }
}
#home {
  height: 100%;
}
.el-header {
  background-color: #e7f1f9;
  height: 110px !important;

  .header__container {
    width: 1280px;
    margin: 0 auto;
    position: relative;

    .header__img {
      position: absolute;
      left: 2%;
      top: 50%;
      margin-top: 22px;
    }
    .header__title__cn {
      position: absolute;
      left: 8%;
      top: 50%;
      margin-top: 22px;
      // width: 387px;
      // height: 37px;
      font-family: MicrosoftYaHei-Bold;
      font-size: 28px;
      color: #183288;
      letter-spacing: 1.75px;
      font-weight: 700;
    }
    .header__title__en {
      position: absolute;
      left: 8%;
      top: 50%;
      margin-top: 62px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #183288;
      letter-spacing: -0.05px;
      font-weight: 400;
    }
    .header__search {
      position: absolute;
      right: 2%;
      top: 50%;
      margin-top: 50px;
    }
    .header__login {
      position: absolute;
      right: 2%;
      top: 50%;
      margin-top: 15px;
    }
    .header__register {
      position: absolute;
      right: 4%;
      top: 50%;
      margin-top: 25px;
    }
  }
}
.el-main {
  padding: 0px !important;
  .main__banner {
    background-color: #183288;
    &__menu__item {
      float: left;
      width: 11%;
    }
    &__centerwarp {
      position: relative;
      margin: 0 auto;
      // width: 76%;
      width: 1240px;
      height: 50px;
    }
  }
  .main__content {
    height: 100%;
    background-color: #e7f1f9;
    // height: calc(100% - 56px);
    z-index: 66;
    &__centerwarp {
      top: 1px;
      z-index: 666;
      background-color: white;
      // width: 76%;
      width: 1240px;
      margin: 0 auto;
      height: 100%;
      overflow: visible;
      &__asideMenu {
        width: 180px;
        margin: 40px auto;
        border-color: #eaeaea;
        border-style: solid;
        border-width: 1px !important;
      }
      .el-image {
        overflow: unset !important;
      }
    }
  }
}
body {
  overflow-x: hidden;
  /* display: flex; */
  min-height: 100vh;
  /* flex-direction: column; */
  margin: 0px;
}
header {
  height: 70px;
  background: #ffffff;
}
.main {
  min-height: calc(100vh - 347px);
}
.el-footer {
  width: 100%;
  // position: fixed;
  padding: 0px;
  bottom: 0px;
  clear: both;
  height: 249px !important;
  background: #183288;
  .link {
    .center__wrapper {
      width: 1240px;
      margin: 0 auto;
    }
    a {
      color: white;
      text-decoration: none;
      width: 1247px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      letter-spacing: 2.28px;
      text-align: center;
      line-height: 39px;
      font-weight: 400;
    }
  }
  .footer__container {
    position: relative;
    max-width: 1247px;
    margin: 0 auto;
    .info {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: white;
      letter-spacing: 3.38px;
      text-align: center;
      line-height: 50px;
      font-weight: 400;
    }
    .DownContent {
      margin-top: 20px;
      .leftFont {
        position: absolute;
        text-align: left;
        .jgawb {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: white;
          letter-spacing: 0;
          line-height: 20px;
          font-weight: 400;
          text-decoration: none;
        }
        .jicpb {
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #749ae3;
          letter-spacing: 0;
          line-height: 20px;
          font-weight: 400;
          text-decoration: none;
        }
        p {
          font-family: "微软雅黑";
          font-size: 12px;
          letter-spacing: 0;
          font-weight: 400;
          color: white;
          line-height: 19px;
        }
        .white {
          color: white;
        }
      }
      .leftPicture {
        position: absolute;
        right: 0%;
        margin-right: 84px;
        .pic1 {
          cursor: pointer;
          position: relative;
          float: left;
          width: 123px;
          height: 89px;
        }
        .pic2 {
          cursor: pointer;
          margin-right: 17px;
          position: relative;
          float: left;
          width: 89px;
          height: 89px;
        }
      }
      .rightPicture {
        position: absolute;
        right: 0%;
        margin-left: 221px;
      }
    }
  }
}
</style>
<style lang="scss">
.el-container {
  height: 100%;
  .el-main {
    overflow: inherit;
    .query {
    }
  }
}
</style>
