import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/PortalHome.vue'

import Query from '../views/publicQuery/Index'
import QueryWebSearch from '../views/publicQuery/SearchWeb.vue'
import QueryAppSearch from '../views/publicQuery/SearchApp.vue'
import QueryWebResult from '../views/publicQuery/ResultWeb.vue'
import QueryAppResult from '../views/publicQuery/ResultApp.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/query',
    name: 'Query',
    component: Query,
    children: [
      {
        path: '/query/webSearch',
        name: 'QueryWebSearch',
        component: QueryWebSearch
      },
      {
        path: '/query/webResult',
        name: 'QueryWebResult',
        component: QueryWebResult
      },
      {
        path: '/query/appSearch',
        name: 'QueryAppSearch',
        component: QueryAppSearch
      },
      {
        path: '/query/appResult',
        name: 'QueryAppResult',
        component: QueryAppResult
      }
    ]
  }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
