/*
判断浏览器 */
export const readUserAgent = () => {
  const u = window.navigator.userAgent.toLowerCase()
  let data = {
    browser: '',
    device:''
  }
  if (u.indexOf('edg') <= -1 && u.indexOf('chrome') <= -1 && u.indexOf('mozilla') <= -1 && u.indexOf('safari') <= -1 && u.indexOf('gecko') <= -1) {
    this.$confirm('请使用Edge、Chrome、FireFox和360等主流浏览器浏览本网站！', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '',
      type: 'warning',
    }).then(() => {

    })
  }
  if(u.indexOf('windows') > -1){
    data.device = 'windows'
  }
  if (u.indexOf('trident') > -1) {
    data.browser = 'ie'
  } else if (u.indexOf('edg') > -1) {
    data.browser = 'edg'
  } else if (u.indexOf('chrome') > -1) {
    data.browser = 'chrome'
  } else if (u.indexOf('mozilla') > -1) {
    data.browser = 'mozilla'
  } else if (u.indexOf('safari') > -1) {
    data.browser = 'safari'
  } else if (u.indexOf('gecko') > -1) {
    data.browser = 'gecko'
  }
  return data
}
export const userAgent = 'edg|chrome|mozilla|safari|gecko'