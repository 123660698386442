<template>
  <div id="result-web">
    <span class="tip">为了保护网络信息安全，保障公民、法人和其他组织的合法权益，维护国家安全和社会公共利益，公安机关将备案网站的基本情况公布如下：</span>
    <div class="title">查询结果</div>
    <el-divider></el-divider>
    <span class="subTitle">
      网站基本情况
    </span>
    <el-descriptions class="margin-top" :column="1" :size="size">
      <el-descriptions-item label="网站名称">
        {{result.webnm}}
      </el-descriptions-item>
      <el-descriptions-item label="网站主域名">
        {{result.maindm}}
      </el-descriptions-item>
      <el-descriptions-item label="开办主体">
        {{result.webnm}}
      </el-descriptions-item>
      <el-descriptions-item label="网站类别">
        {{result.webtype}}
      </el-descriptions-item>
    </el-descriptions>
    <span class="subTitle">
      网站所有者基本情况
    </span>
    <el-descriptions class="margin-top" :column="1" :size="size">
      <el-descriptions-item label="开办者名称">
        {{result.unitnm}}
      </el-descriptions-item>
      <el-descriptions-item label="公安备案号">
        {{result.unitnm}}
      </el-descriptions-item>
      <el-descriptions-item label="备案地公安机关">
        {{result.unitnm}}
      </el-descriptions-item>
      <el-descriptions-item label="联网备案时间">
        {{result.audittime}}
      </el-descriptions-item>
    </el-descriptions>
    <span class="jubao">您如果发现该网站在网络经营活动中存在违反国家法律、法规的经营行为，请进行
      <a href="http://cyberpolice.mps.gov.cn/wfjb/" target="_blank">举报</a>。</span>

  </div>
</template>

<script>
export default {
  name: 'PublicQueryWebResult',

  data() {
    return {
      size: 'medium',
      result: this.$route.query.result
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
#result-web {
  margin-right: 20px;
  .tip {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #b10000;
    letter-spacing: 0;
    line-height: 39px;
    font-weight: 400;
  }
  .title {
    font-family: MicrosoftYaHei-Bold;
    font-size: 18px;
    color: #183288;
    letter-spacing: 0;
    line-height: 42px;
    font-weight: 700;
  }
  .subTitle {
    font-family: MicrosoftYaHei-Bold;
    font-size: 14px;
    color: #0f6feb;
    letter-spacing: 0;
    line-height: 42px;
    font-weight: 700;
  }
  .jubao {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #030303;
    letter-spacing: 0;
    line-height: 39px;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
#result-web {
  .el-descriptions-item__label.is-bordered-label {
    width: 150px;
  }
  .el-divider {
    margin: 0px 0px;
  }
}
</style>
