<template>
  <router-view />
</template>
<script>
import { readUserAgent } from "./utils/userAgent.js";
export default {
  name: "App",
  created() {
    let data = readUserAgent();
    if (data.device !== "windows") {
      // this.$confirm(
      //   "本网站建议使用电脑端，支持Edge、Chrome、FireFox和360等主流浏览器浏览本网站！",
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "",
      //     type: "warning",
      //   }
      // ).then(() => {});
    }
  },
};
</script>
<style lang="scss">
html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
body {
  height: 100%;
  margin: 0px;
  overflow-x: hidden;
}
#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
