<template>
  <div class="center">
    <div class="select">
      <el-form label-position="right" label-width="120px" :model="form">
        <el-form-item label="请选择查询种类">
          <el-select v-model="form.type" style="width:400px;" @change="handleClickSelect">
            <el-option label="App名称" value="name"></el-option>
            <el-option label="备案号" value="recordNo"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div class="form">
      <div v-if="form.type==='name'">
        <AppSearchName></AppSearchName>
      </div>
      <div v-if="form.type==='recordNo'">
        <AppSearchRecordNo></AppSearchRecordNo>
      </div>
      <div v-show="searchFail">查找失败！原因：{{reason}}</div>
    </div>
  </div>
</template>

<script>

import AppSearchRecordNo from './AppSearchRecordNo'
import AppSearchName from './AppSearchName'

export default {
  name: 'SearchApp',
  components: {
    AppSearchRecordNo, AppSearchName
  },
  data() {
    return {
      form: {
        type: 'name'
      },
      reason: '',
      searchFail: false,
      activeWeb: 'name'
    }
  },

  mounted() {

  },

  methods: {
    handleClickSelect() {
    },
    handleClickTab() {
      this.searchFail = false
      this.reason = ''
    },
    handleSearchWeb(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.popValidatePic(formName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    popValidatePic(formName) {
      this.currentFormName = formName
      this.$refs.verify.show()
    }
  }
}
</script>

<style lang="scss" scoped>
.center {
  width: 525px;
  margin: 0 auto;
}
</style>
