<template>
  <div>
    <el-form ref="WebRecordNo" label-position="right" label-width="120px" :model="WebRecordNo"
      :rules="WebRecordNoRules">
      <el-form-item label="联网备案号" prop="recordNo">
        <el-input v-model="WebRecordNo.recordNo" style="width:400px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearchWeb('WebRecordNo')">查询</el-button>
      </el-form-item>
    </el-form>
    <div v-show="searchFail">查找失败！原因：{{reason}}</div>
    <Verify ref="verify" :mode="'pop'" :captcha-type="'blockPuzzle'"
      :img-size="{ width: '330px', height: '155px' }" @success="success"></Verify>
  </div>
</template>

<script>
import { searchWeb } from '@/api/query'
import Verify from '@/components/verifition/Verify'
export default {
  name: 'InstFrontVueWebSearchRecordNo',
  components: {
    Verify
  },
  data() {
    return {
      WebRecordNo: {
        recordNo: ''
      },
      WebRecordNoRules: {
        recordNo: { required: true, message: '请输入', trigger: 'blur' }
      },
      currentFormName: '',
      reason: '',
      searchFail: false
    }
  },

  mounted() {

  },

  methods: {
    handleSearchWeb(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.popValidatePic(formName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    popValidatePic(formName) {
      this.currentFormName = formName
      this.$refs.verify.show()
    },
    submitSearchWeb() {
      const formName = this.currentFormName
      searchWeb({
        polnm: this[formName].recordNo
      })
        .then(resObject => {
          if (resObject.code === 200) {
            this.$router.push({
              name: 'QueryWebResult',
              query: {
                result: resObject.data,
                t: +new Date() // 保证每次点击路由的query项都是不一样的，确保会重新刷新view
              }
            })
          } else {
            this.searchFail = true
            this.reason = resObject.data
          }
        })
        .catch(err => {
          console.log('获取网站信息失败,err=', err)
        })
    },
    success() {
      this.submitSearchWeb()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
