import request from '../utils/request'

export function searchWeb(data) {
  return request({
    url: '/cyber_portal/query/websearch',
    method: 'post',
    data: data
  })
}

export function searchAPP(data) {
  return request({
    url: '/cyber_portal/query/appsearch',
    method: 'post',
    data: data
  })
}
