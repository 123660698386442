/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */

import request from './../utils/axios' // 组件内部封装的axios
import { getStore } from '@/utils/store'
// import request from "@/api/axios.js"       //调用项目封装的axios
const baseURL = process.env.VUE_APP_BASE_URL

// 获取验证图片  以及token
export function reqGet(data) {
  return request({
    url: `${baseURL}/cyber_sso/captcha/get`,
    method: 'post',
    data,
    headers: {
      'timestamp': Date.now(),
      'zo': getStore({ name: 'zo' })
    }
  })
}

// 滑动或者点选验证
export function reqCheck(data) {
  return request({
    url: `${baseURL}/cyber_sso/captcha/check`,
    method: 'post',
    data,
    headers: {
      'timestamp': Date.now(),
      'zo': getStore({ name: 'zo' })
    }
  })
}

