import request from '../utils/request'
import store from '../store'
import { getStore } from '@/utils/store'

export function whoami() {
  return request({
    url: '/cyber_sso/self/locate',
    method: 'post',
    headers: {
      token: store.getters.token,
      zo: getStore({ name: 'zo' }),
      timestamp: Date.now()
    }
  })
}
